import React from "react";
import { render } from "react-dom";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";

export default function InteriorHero(props) {
    const {
        title,
        showTitle,
        thirdLevel,
        heroHeadline,
        image,
    } = props;
    
    return (
        <>
            <section 
                className="interior-hero" 
                style={{
                    display: "block",
                    position: "relative",
                }}
            >
                <div className={"wb-hero__bg trustsection__bgimg " + (showTitle ? 'this--showtitle' : '')}>
                    <PreviewCompatibleImage imageInfo={image}/>
                </div>

                <div className={"wb-hero__content"+ ' ' +(thirdLevel ? 'this--thirdlevel' : '')}>
                    <div className="container">
                        <div className="section" style={{paddingBottom: "1rem"}}>
                            <div className="columns is-gapless">
                                <div className="column is-half"> {showTitle && ( <p className="hero-headline"> {title} </p> )} </div>
                                <div className="column is-half" style={{alignSelf: "flex-end"}}></div>
                            </div>
                        </div>
                        <div className="section" style={{paddingBottom: "0", paddingTop: "0"}}>
                            <div className="columns is-gapless">
                                <div className="column is-half pt-5 pl-5 pr-5 pb-0 wb-hero__intro">
                                    <div className="content px-5 pt-5 pb-0">
                                        <div className="tile">
                                            <h1>{heroHeadline}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="column is-half" style={{padding: "0"}}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );

}